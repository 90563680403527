import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Markdown from 'react-markdown';

const About = () =>{
	const query = useStaticQuery(graphql`
		query {
			allStrapiPages(filter: {page_slug: {eq: "about_us"}}){
			    edges{
					node{
						id
						page_title
						page_slug
						page_content
						image {
						  	childImageSharp {
							    fluid {
							      	src
							    }
						  	}
						}
					}
			    }
			}
		}
	`)
	const about = [];
	query.allStrapiPages.edges.forEach(({node})=>{
		about.push(node);
	})
	let a = about[0]
	return(
		<div id="blog-single" style={{marginBottom: '-100px'}}>
		    <div className="container">
		        <div className="row">
		            <div className="col-lg-12 col-md-12 col-xs-12">
		                <div className="blog-post">
		                	<div className="post-thumb">
		                	    <img src={a.image ? a.image.childImageSharp.fluid.src : null} alt="" className="img-fluid"/>
		                	</div>
		                    <div className="post-content">
		                        <h3>{a.page_title}</h3>
		                        <Markdown source={a.page_content} escapeHtml={true}/>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
	)
}

export default About