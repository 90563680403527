import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import SubHeader from '../components/subheader'
import About from '../components/about-us'
import TeamSection from '../components/team'
//import ClientSection from '../components/clients'

const Abouts = () => {
    return (
        <Layout>
            <SEO title="About Us" />
            <SubHeader title='About Us'/>
            <About/>
            <TeamSection/>
        </Layout>
    )
}

export default Abouts