import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Avatar from '../images/avatar.png';

const Team = () => {
	const query = useStaticQuery(graphql`
	  	query{
		    allTeamsJson {
				edges {
					node {
						title
						desc
					}
				}
		    }
		    allStrapiTeams {
		        edges {
					node {
						id
						designation
						instragram_profile
						facebook_profile
						linkedin_profile
						name
						profile_picture {
							childImageSharp {
								fluid {
									src
								}
							}
						}
					}
		        }
		    }
	  	}
	`)

	let teamHeader = [];
	query.allTeamsJson.edges.forEach(({node})=>{
	  teamHeader.push(node);
	})
	
	teamHeader = teamHeader[0];

	let teams = [];
	query.allStrapiTeams.edges.forEach(({node})=>{
		teams.push(node)
	})
	return (
		<section id="team" className="section-padding text-center">
			<div className="container">
				<div className="section-header text-center">
					<h2 className="section-title wow fadeInDown">{teamHeader.title}</h2>
					<p dangerouslySetInnerHTML={{__html: teamHeader.desc}}/>
				</div>
				<div className="row">
					{
						teams.map((item, index)=>{
							return (
								<div className="col-sm-6 col-md-6 col-lg-3" key={`key_${index}`}>
									<div className="team-item text-center wow fadeInRight">
										<div className="team-img">
											<img className="img-fluid" src={item.profile_picture ? item.profile_picture.childImageSharp.fluid.src : Avatar} alt=""/>
											<div className="team-overlay">
												<div className="overlay-social-icon text-center">
													<ul className="social-icons">
														<li><a href={item.linkedin_profile}><i className={`lni-linkedin-filled`} aria-hidden="true"></i></a></li>
														<li><a href={item.facebook_profile}><i className={`lni-facebook-filled`} aria-hidden="true"></i></a></li>
														<li><a href={item.instragram_profile}><i className={`lni-instagram-filled`} aria-hidden="true"></i></a></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="info-text">
											<h3><a href="#">{item.name}</a></h3>
											<p>{item.designation}</p>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</section>
	)
}

export default Team